import * as React from 'react';
import { graphql } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation} from 'gatsby-plugin-react-i18next';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';

// Components
import Layout from '../components/layout/Layout';
import ContactForm from '../components/form/Contact';

// Images
import headerBackgroundImage from '../images/svg/components/card-11.svg';
import markerIcon from '../images/markers/marker.png';

const ContactPage = ({ data }) => {
  
  const {t} = useTranslation();

  // Google Map
	const styles = [
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [{
          "color": "#e9e9e9"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "landscape",
      "elementType": "geometry",
      "stylers": [{
          "color": "#f5f5f5"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.fill",
      "stylers": [{
          "color": "#ffffff"
        },
        {
          "lightness": 17
        }
      ]
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry.stroke",
      "stylers": [{
          "color": "#ffffff"
        },
        {
          "lightness": 29
        },
        {
          "weight": 0.2
        }
      ]
    },
    {
      "featureType": "road.arterial",
      "elementType": "geometry",
      "stylers": [{
          "color": "#ffffff"
        },
        {
          "lightness": 18
        }
      ]
    },
    {
      "featureType": "road.local",
      "elementType": "geometry",
      "stylers": [{
          "color": "#ffffff"
        },
        {
          "lightness": 16
        }
      ]
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [{
          "color": "#f5f5f5"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [{
          "color": "#dedede"
        },
        {
          "lightness": 21
        }
      ]
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [{
          "visibility": "on"
        },
        {
          "color": "#ffffff"
        },
        {
          "lightness": 16
        }
      ]
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [{
          "saturation": 36
        },
        {
          "color": "#333333"
        },
        {
          "lightness": 40
        }
      ]
    },
    {
      "elementType": "labels.icon",
      "stylers": [{
        "visibility": "off"
      }]
    },
    {
      "featureType": "transit",
      "elementType": "geometry",
      "stylers": [{
          "color": "#f2f2f2"
        },
        {
          "lightness": 19
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.fill",
      "stylers": [{
          "color": "#fefefe"
        },
        {
          "lightness": 20
        }
      ]
    },
    {
      "featureType": "administrative",
      "elementType": "geometry.stroke",
      "stylers": [{
          "color": "#fefefe"
        },
        {
          "lightness": 17
        },
        {
          "weight": 1.2
        }
      ]
    }
  ];

  const center = {
    lat: parseFloat(data.site.siteMetadata.addressLatitude),
    lng: parseFloat(data.site.siteMetadata.addressLongitude)
  };  

  const options = {
    zoom: 14,
    maxZoom: 18,
    center: center,
    scrollwheel: false,
    mapTypeControl: 0,
    draggable: false,
    zoomControl: 1,
    scaleControl: 0,
    streetViewControl: 0,
    fullscreenControl: false,
    styles: styles,
  }

  return (
    <Layout
      pageType="classic" 
      pageTitle={t('meta.title')} 
      pageDescription={t('meta.description')} 
      pageKeywords={t('meta.keywords')} 
      headerClass="navbar-absolute-top navbar-light navbar-show-hide" 
    >
      <div className="position-relative bg-img-start" style={{backgroundImage: 'url(' + headerBackgroundImage + ')'}}>
				<div className="container content-space-t-3 content-space-t-lg-3 content-space-b-2 position-relative zi-2">
					<div className="w-md-75 w-lg-50 text-center mx-md-auto mb-5 mb-md-9">
						<h1>{t('title')}</h1>
						<p>{t('subtitle')}</p>
					</div>
					<div className="row">
						<div className="container">
							<div className="mx-auto" style={{maxWidth:'40rem'}}>
								<div className="card">
									<div className="card-body">
                    <ContactForm />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="shape shape-bottom zi-1">
					<svg width="3000" height="500" viewBox="0 0 3000 500" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M0 500H3000V0L0 500Z"/>
					</svg>
				</div>
			</div>		
			<div className="bg-light position-relative rounded-2">
				<div className="container content-space-1 content-space-lg-3">
					<div className="row justify-content-md-end">
						<div className="col-md-6 col-lg-5">
							<div className="card card-lg position-relative zi-999">
								<div className="card-body">
									<div className="mb-5">
                    <div className="d-flex align-items-lg-center">
                      <div className="flex-shrink-0">
                        <StaticImage className="avatar avatar-lg avatar-circle" objectFit="contain" quality="50" src="../images/logos/thibault-crouzet.png" alt={t('common.image.alt')} />
                      </div>
                      <div className="flex-grow-1 ms-4">
                        <div className="row">
                          <div className="col-lg mb-3 mb-lg-0">
                            <div className="page-header-title h2">
                              {data.site.siteMetadata.title} 
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
									</div>
									<div className="d-flex mb-5">
										<div className="flex-shrink-0">
											<span className="svg-icon svg-icon-sm text-primary me-3">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path d="M6 21C6 21.6 6.4 22 7 22H17C17.6 22 18 21.6 18 21V20H6V21Z" fill="#035A4B"/>
													<path opacity="0.3" d="M17 2H7C6.4 2 6 2.4 6 3V20H18V3C18 2.4 17.6 2 17 2Z" fill="#035A4B"/>
													<path d="M12 4C11.4 4 11 3.6 11 3V2H13V3C13 3.6 12.6 4 12 4Z" fill="#035A4B"/>
												</svg>
											</span>
										</div>
										<div className="flex-grow-1">
											<h5 className="mb-1">{t('callUs')}</h5>
                      {data.site.siteMetadata.telephone ? 
											  <span className="d-block small"><a className="link-dark" href={"tel:" + data.site.siteMetadata.telephoneIndex}>{data.site.siteMetadata.telephone}</a></span>
                      : null }
										</div>
									</div>
									<div className="d-flex mb-5">
										<div className="flex-shrink-0">
											<span className="svg-icon svg-icon-sm text-primary me-3">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path opacity="0.3" d="M21 18H3C2.4 18 2 17.6 2 17V7C2 6.4 2.4 6 3 6H21C21.6 6 22 6.4 22 7V17C22 17.6 21.6 18 21 18Z" fill=""></path>
													<path d="M11.4 13.5C11.8 13.8 12.3 13.8 12.6 13.5L21.6 6.30005C21.4 6.10005 21.2 6 20.9 6H2.99998C2.69998 6 2.49999 6.10005 2.29999 6.30005L11.4 13.5Z" fill=""></path>
												</svg>
											</span>
										</div>
										<div className="flex-grow-1">
											<h5 className="mb-1">{t('emailUs')}</h5>
                      {data.site.siteMetadata.email ? 
											  <span className="d-block small"><a className="link-dark" href={"mailto:" + data.site.siteMetadata.email}>{data.site.siteMetadata.email}</a></span>
                      : null }
										</div>
									</div>
									<div className="d-flex">
										<div className="flex-shrink-0">
											<span className="svg-icon svg-icon-sm text-primary me-3">
												<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
													<path opacity="0.3" d="M18.0624 15.3454L13.1624 20.7453C12.5624 21.4453 11.5624 21.4453 10.9624 20.7453L6.06242 15.3454C4.56242 13.6454 3.76242 11.4452 4.06242 8.94525C4.56242 5.34525 7.46242 2.44534 11.0624 2.04534C15.8624 1.54534 19.9624 5.24525 19.9624 9.94525C20.0624 12.0452 19.2624 13.9454 18.0624 15.3454ZM13.0624 10.0453C13.0624 9.44534 12.6624 9.04534 12.0624 9.04534C11.4624 9.04534 11.0624 9.44534 11.0624 10.0453V13.0453H13.0624V10.0453Z" fill=""></path>
													<path d="M12.6624 5.54531C12.2624 5.24531 11.7624 5.24531 11.4624 5.54531L8.06241 8.04531V12.0453C8.06241 12.6453 8.46241 13.0453 9.06241 13.0453H11.0624V10.0453C11.0624 9.44531 11.4624 9.04531 12.0624 9.04531C12.6624 9.04531 13.0624 9.44531 13.0624 10.0453V13.0453H15.0624C15.6624 13.0453 16.0624 12.6453 16.0624 12.0453V8.04531L12.6624 5.54531Z" fill=""></path>
												</svg>
											</span>
										</div>
										<div className="flex-grow-1">
											<h5 className="mb-1">{t('visitUs')}</h5>
                      {data.site.siteMetadata.addressFull ? 
											  <span className="d-block small"><a className="link-dark" href={"http://maps.google.com/maps?daddr=" + data.site.siteMetadata.addressLatitude + "%2C" + data.site.siteMetadata.addressLongitude} target="_blank" rel="noopener noreferrer">{data.site.siteMetadata.addressFull}</a></span>
                      : null }
                    </div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="position-md-absolute top-0 start-0 bottom-0 end-0">
          <LoadScript
            googleMapsApiKey={process.env.GATSBY_GOOGLE_API_KEY}
          >
            <GoogleMap
              mapContainerClassName={'map'}
              options={options}
            >
              <Marker
                icon={{ 
                  url: markerIcon,
                  size: {width: 40, height: 40}, 
                  scaledSize: {width: 40, height: 40}, 
                }}
                position={center}
              />
            </GoogleMap>
          </LoadScript>
				</div>
			</div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query ($language: String!) {
    site: site {
      siteMetadata {
        title
        addressStreet
        addressPostalCode
        addressCity
        addressCountry
        addressFull
        addressGoogleDirection
        addressLatitude
        addressLongitude
        email
        telephone
        telephoneIndex
      }
    }
    locales: allLocale(filter: {ns: {in: ["common", "header", "contact", "contact-form", "footer"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

